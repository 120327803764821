@font-face {
  font-family: 'charlieproseek';
  src: url('font/charlieproseek.eot?15353456');
  src: url('font/charlieproseek.eot?15353456#iefix') format('embedded-opentype'),
       url('font/charlieproseek.woff2?15353456') format('woff2'),
       url('font/charlieproseek.woff?15353456') format('woff'),
       url('font/charlieproseek.ttf?15353456') format('truetype'),
       url('font/charlieproseek.svg?15353456#charlieproseek') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'charlieproseek';
    src: url('../font/charlieproseek.svg?15353456#charlieproseek') format('svg');
  }
}
*/

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "charlieproseek";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-badge_0:before { content: '\e800'; } /* '' */
.icon-forum:before { content: '\e801'; } /* '' */
.icon-lupa:before { content: '\e802'; } /* '' */
.icon-eventos:before { content: '\e803'; } /* '' */
.icon-gerenciar_equipe:before { content: '\e804'; } /* '' */
.icon-seta_direita:before { content: '\e805'; } /* '' */
.icon-seta_esquerda:before { content: '\e806'; } /* '' */
.icon-visao_card:before { content: '\e807'; } /* '' */
.icon-seta_cima:before { content: '\e808'; } /* '' */
.icon-seta_bx:before { content: '\e809'; } /* '' */
.icon-visao_lista:before { content: '\e80a'; } /* '' */
.icon-historico_emails:before { content: '\e80b'; } /* '' */
.icon-home:before { content: '\e80c'; } /* '' */
.icon-modulo:before { content: '\e80d'; } /* '' */
.icon-processos_sincronia:before { content: '\e80e'; } /* '' */
.icon-trilhas:before { content: '\e80f'; } /* '' */
.icon-usuarios:before { content: '\e810'; } /* '' */
.icon-atendimento:before { content: '\e811'; } /* '' */
.icon-menu:before { content: '\e812'; } /* '' */
.icon-curriculo:before { content: '\e813'; } /* '' */
.icon-linkedin:before { content: '\f0e1'; } /* '' */
.icon-relatorio:before { content: '\e80d'; } /* '' */
