@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css?family=Khand:400,600');
@import url('./styles/font.default.scss');
@import url('https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300ita‌​lic,400italic,500,500italic,700,700italic,900italic,900');


@import '~jsondiffpatch/dist/formatters-styles/html.css';
@import '~jsondiffpatch/dist/formatters-styles/annotated.css';

@import '@toast-ui/editor/dist/toastui-editor.css';
@import 'tui-color-picker/dist/tui-color-picker.css';
@import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
// @import '~@toast-ui/editor/dist/toastui-editor.css';
@import '~angular-calendar/css/angular-calendar.css';
@import 'assets/tg4-fonts.css';

@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/assets/icons.css';
@import '~@swimlane/ngx-datatable/themes/material.scss';
@import 'styles/buttons/index.scss';
@import 'styles/vendors/@angular/material/index.scss';

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/font/helvetica-neue.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.mat-datepicker-content .actions {
  position: absolute;
  bottom: 65px;
  right: -10px;
}

.mat-datepicker-content .time-container {
  padding-bottom: 5px;
}


.snackbar {
  simple-snack-bar>span {
    white-space: pre-line;
  }
}


body {
  .mat-form-field-label {
    line-height: 22px;
  }

  .mat-form-field-label:not(.mat-form-field-empty)>span {
    position: relative;
    height: 100%;
    top: 3px;
  }

  .ngx-datatable.material {
    box-shadow: 0 3px 3px -2px rgba(0, 0, 0, .2),
      0 3px 4px 0 rgba(0, 0, 0, .14),
      0 1px 8px 0 rgba(0, 0, 0, .12);
  }

  .ngx-datatable.material .datatable-body .datatable-body-row {
    border-bottom: 1px solid rgba(0, 0, 0, .12);
  }

  .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
    color: #707070;
  }

  .ngx-datatable.material.single-selection .datatable-body-row.active:hover,
  .ngx-datatable.material.single-selection .datatable-body-row.active:hover .datatable-row-group,
  .ngx-datatable.material.multi-selection .datatable-body-row.active:hover,
  .ngx-datatable.material.multi-selection .datatable-body-row.active:hover .datatable-row-group,
  .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover,
  .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover,
  .datatable-row-group .ngx-datatable.material.single-selection .datatable-body-row.active,
  .ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group,
  .ngx-datatable.material.multi-selection .datatable-body-row.active,
  .ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group,
  .ngx-datatable.material.multi-click-selection .datatable-body-row.active,
  .ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
    background-color: #eeeeee;
  }

  .ngx-datatable .datatable-header .datatable-header-cell .datatable-header-cell-template-wrap {
    overflow: hidden;
    text-overflow: inherit;
  }

  .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-celll {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell,
  .ngx-datatable.material .datatable-header .datatable-header-cell {
    padding: 0.9rem 0.5rem;
  }

}

.tui-editor-defaultUI-toolbar button.tui-scrollsync {
  display: none !important;
}

body {
  margin: 0px;
  background-color: var(--main-background);
}

button {
  border: none;
}

p {
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
}

.student-report .mat-dialog-container {
  max-height: 95vh;
  overflow-y: auto;
}

.new-question-dialog-fullscreen {

  .mat-dialog-container {
    max-height: none;
    max-width: none;
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

}

body control-messages p {
  color: red;
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 500;
  margin-top: -10px;
  margin-bottom: 15px;
}

mat-form-field {
  &.whiteBorder {
    color: white !important;

    .mat-form-field-underline {
      background-color: white;
    }

    .mat-form-field-ripple {
      background-color: white !important;
    }
  }

  &.noBorder {
    .mat-form-field-infix {
      border-top: none;
    }
  }
}

.inner-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0px 15px;
  overflow: hidden;
}

button.btn-test {
  background: var(--buttons-default-color);
  color: var(--button-text-color);
  padding: 8px 24px;
  border-radius: var(--button-border-radius);
  font-size: 13px;
  font-weight: var(--button-font-weight);
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
   &.primary {
    background: var(--primary-color);
    color: var(--button-secondary-text-color);
  }

  &.remove-btn {
    --danger-color: #FF6A6A;
    background: var(--danger-color);
  }

  &[disabled] {
    background: gray;
  }

  mat-icon {
    padding-left: 7.5px;
  }
}

button.btn-outline {
  background: transparent;
  color: var(--primary-color);
  padding: 6px 24px;
  border-radius: 6px;
  border: 2px solid var(--primary-color);
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
}


/* Calendar Styles */
.cal-header,
.cal-hour-segment .cal-time {
  color: #727272;
  text-transform: uppercase;
}

mwl-calendar-event-actions {
  float: right;
  text-align: center;
  margin-bottom: 15px;

  .cal-event-actions .cal-event-action {
    text-decoration: initial;

    span {
      color: white;
      border-radius: 4px;
      padding: 5px 35px;

      &.red {
        background: #ff4376;
      }

      &.green {
        background: var(--finished-progress-color);
      }

      &.purple {
        background: #bd62ff;
      }

      &.orange {
        background: #ffa500;
      }
    }
  }
}

.cal-month-view {
  .cal-day-badge {
    background-color: var(--primary-color);
  }

  .cal-open-day-events {
    background-color: rgb(232, 232, 232);
    box-shadow: initial;
    color: #727272;

    >div {
      display: flex;
      justify-content: space-between;

      mwl-calendar-event-title {
        flex-grow: 1;
      }
    }

    >div:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  .cal-event {
    width: 13px;
    height: 13px;
    margin-right: 7px;
  }

  .cal-event-title {
    font-weight: 700;
    font-size: 18px;

    &:hover {
      text-decoration: initial;
    }
  }
}

.cal-week-view {
  .cal-event {
    .cal-event-title {
      color: rgb(10, 10, 10);
    }
  }

  .cal-day-column {
    .cal-event {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      font-weight: 600;
      white-space: initial;
      line-height: 15px;
      padding-top: 5px;
    }
  }
}

.cal-day-view {
  .cal-event {
    padding: 12px;

    .cal-event-title {
      color: rgb(10, 10, 10);
    }
  }

  .cal-event-container {
    width: 90% !important;

    mwl-calendar-day-view-event {
      .cal-event {
        span {
          font-weight: 600;
        }
      }
    }
  }

  .cal-hour-rows {
    overflow-x: hidden;
  }
}

div.calendar-views {
  display: flex;
  justify-content: space-around;

  div {
    flex: 1;
    border-bottom: 3px solid;
    font-weight: 600;
    text-align: center;
    padding-bottom: 5px;
    color: rgb(200, 200, 200);
    cursor: pointer;

    &.active {
      color: var(--primary-color);
    }
  }
}

div.calendar-content {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  div.calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 8px;
    border-bottom: 1px solid rgb(225, 225, 225);

    div {
      width: 24px;
      background: var(--primary-color);
      border-radius: 100%;
      padding: 5px 12px 4px 4px;
      font-size: 23px;
      cursor: pointer;

      // &:last-child {
      //   transform: rotate(180deg);
      // }
    }

    h3 {
      text-transform: uppercase;
      font-size: 21px;
      color: #727272;
    }
  }
}

body mat-sidenav-content {
  max-height: 100vh;
}

.custom-dialog-container {

  .mat-dialog-container,
  .mat-dialog-content {
    padding: 0;
    margin: 0;
  }
}

.cockpit-mat-panel.mat-select-panel {
  max-width: 500px !important;
  overflow: hidden;
}

.custom-generic-dialog-class {
  mat-dialog-container {
    overflow: hidden;
  }
}

.tooltip-class {
  background: white !important;
  color: var(--primary-color) !important;
  font-size: 12px;
  line-height: 18px;
  box-shadow: var(--box-shadow);
}

.hide-inbenta {

  div.inbenta,
  #inbenta-bot-sdk {
    display: none !important;
  }
}
