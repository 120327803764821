button.btn-test {
  background: var(--buttons-default-color);
  color: white;
  padding: 8px 24px;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.primary {
    background: var(--primary-color);
  }

  &.remove-btn {
    --danger-color: #FF6A6A;
    background: var(--danger-color);
  }

  &[disabled] {
    background: gray;
  }

  mat-icon {
    padding-left: 7.5px;
  }
}

.btn-outline {
  background: transparent;
  color: var(--primary-color);
  padding: 6px 24px;
  border-radius: 6px;
  border: 2px solid var(--primary-color);
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;

  &[disabled] {
    color: gray;
    border: 2px solid gray;
  }
}
