@use '@angular/material'as mat;
@import '@angular/material/theming';

@include mat.core();

$my-app-primary: mat.define-palette(mat.$cyan-palette);
$my-app-accent: mat.define-palette(mat.$cyan-palette, 500, 900, A100);
$my-app-theme: mat.define-light-theme($my-app-primary, $my-app-accent);
$datatable-header-cell-color: #707070;
$datatable-body-cell-color:#707070;
$ngx-datatable-selected-active-background: #eeeeee;
$ngx-datatable-selected-active-background-hover: #eeeeee;
$ngx-datatable-selected-active-background-focus: #eeeeee;

@include mat.all-component-themes($my-app-theme);


:root {
  --primary-color: #23BCD1;  
  --alternate-primary-color: #239BD1;  
  --semi-primary-color: #89D2DC;  
  --scroll-background: #0f537b;  
  --menu-content-diveder: #64cddb;  
  --card-primary-color: #23BCD1;  
  --third-primary-color: #1988c8;  
  --divider-color: #1c96df;  
  --selected-color: #43b3f3;  
  --progress-bar-uncompleted: #e8e8e8;  
  --progress-bar-completed: #80d1dc;  
  --progress-bar-expected: #FF8D9E;  
  --progress-bar-average-class: #FFE08D;  
  --danger-color: #FF8D9E;  
  --warn-color: #FFE08D;  
  --success-color: #A8F5B4;  
  --text-color: #5D5D5D;  
  --alt-text-color: #4f4f4f;  
  --box-shadow: 1px 2px 6px 0px rgba(180, 180, 180, 1);  
  --header-accent: #efefef;  
  --main-background: #ffffff;  
  --light-color: #1aAFc9;  
  --footer-background: #EDEDED;  
  --buttons-default-color: #239BD1;  
  --empty-badge: #89d2dc;  
  --glasses-icon: #0369db;  
  --brain-icon: #7564f5;  
  --brain-dark-icon-shadow: #4a4a4a;  
  --pencil-icon: #9afa00;  
  --answer-background: #FF4671;  
  --finished-progress-color: #06E295;  
  --login-url-background: url('../assets/img/loginbg.png');  
  --track-card-aling: space-between; 
  --track-card-color: #ededed; 
  --module-card-color: #fcfcfc; 
  --module-card-text-color: #858384; 
  --button-text-color: white; 
  --button-secondary-text-color: white;
  --module-card-title-text-color: #858384; 
  --gradient-left-color: #23bcd1; 
  --gradient-right-color: #23bcd1; 
  --light-text-color: #858384;
  --search-button-color: #89d2dc;
  --module-header-level-color: #239BD1;
  --module-text-color: white;
  --module-subject-font-size: 300;
  --module-card-margin-top: 40px;
  --module-card-button-text-color:#250D8D;
  --module-card-button-color: #FBC34A;
  --module-sidebar-font-weitght:300;
  --track-card-padding-bottom:0px;
  --track-card-title-color:#858384;
  --track-card-title-weight:600;
  --track-card-title-size:16px;
  --track-card-title-margin-top:20px;
  --track-card-module-font-weight:300;
  --track-card-module-color:#858384;
  --track-card-excerpt-color:#858384;
  --button-border-radius:6px;
  --button-font-weight:500;
  --module-button-color:#fff;
  --module-button-text-color:#23BCD1;
  --track-card-bg: #ededed; 
  --track-card-font-color: #858384; 
  --track-card-margin-top: 10px; 
  --track-card-title-height: 20%; 
  --track-card-title-font-weight: 500; 
  --track-card-font-size: 16px; 
  --track-card-module-height: 10%; 
  --track-card-p-font-weight: 500; 
  --users-text-import-button-color: white;
  font-family: 'Montserrat';
}

img.logo {
  margin-left: 7px;
  height: 42px;
  width: fit-content !important;
}